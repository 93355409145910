import React from "react"
import styled from "styled-components"

const Container = styled.div`
  z-index: 2;
  background: ${props => props.theme.colors.sectionBg};
  padding: 5rem 8%;
  display: grid;
  place-items: center;
  text-align: center;
`

const H4 = styled.h4`
  line-height: 1.5rem;
  font-size: 1rem;
  @media screen and (min-width: 900px) {
    font-size: 1.2rem;
  }
`

const Description = () => {
  return (
    <Container>
      <H4>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim nihil,
        repellendus iusto modi tempore corporis debitis doloribus, obcaecati
        aliquid quod quas voluptatum in voluptate alias ipsum neque ducimus
        maiores error.
      </H4>
    </Container>
  )
}

export default Description
