import React from "react"
import {
  RiDossierLine,
  RiBook2Line,
  RiCalendarCheckFill,
  RiTeamLine,
} from "react-icons/ri"
import Library from "../assets/Library.svg"
import Calendar from "../assets/Calendar.svg"
import Community from "../assets/Community.svg"

export default [
  {
    id: 1,
    icon: <RiDossierLine />,
    img_src: Library,
    title: "Library",
    text: `I'm baby meditation tofu chillwave, distillery messenger bag thundercats chicharrones kale chips gochujang. Banh mi direct trade marfa salvia.`,
  },
  {
    id: 2,
    icon: <RiCalendarCheckFill />,
    img_src: Calendar,
    title: "Calendar",
    text: `I'm baby meditation tofu chillwave, distillery messenger bag thundercats chicharrones kale chips gochujang. Banh mi direct trade marfa salvia.`,
  },
  {
    id: 3,
    icon: <RiTeamLine />,
    img_src: Community,
    title: "Community",
    text: `I'm baby meditation tofu chillwave, distillery messenger bag thundercats chicharrones kale chips gochujang. Banh mi direct trade marfa salvia.`,
  },
]
