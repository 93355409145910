import React from "react"
import Title from "./Title"
import Pathology from "./Pathology"
import { Link } from "gatsby"

const Pathologies = () => {
  return (
    <>
      <h2>pathologies list</h2>
      <p>
        {" "}
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Illum ex sit
        iusto quas voluptatum asperiores, quos tempore fugit, nostrum laborum
        nemo, aliquid ipsa deserunt sint amet beatae quo minus cupiditate.
      </p>
    </>
  )
}

export default Pathologies
