import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const Button = styled.button`
  appearance: none;
  border: none;
  outline: none;
  cursor: pointer;
  height: 2rem;
  min-width: min-content;
  border-radius: ${props => props.theme.radius};
  background-color: ${props => props.theme.colors.primary} /*fallback*/
  background: ${props => props.theme.colors.buttonGradient.gradient};
    background: ${props => props.theme.colors.buttonGradient.gradientWebkit};
    background: ${props => props.theme.colors.buttonGradient.gradientO};  
  /* cuz i had 2 lateral lines , i did this */
  background-position-x: 10%;;
  background-size: 150%;
  box-shadow: ${props => props.theme.elevations.elevation3};
  -webkit-box-shadow: ${props => props.theme.elevations.elevation3};
  padding: 0.3rem 0.7rem;
  /* rules for the elements inside it */
  transition: ${props => props.theme.transition};
  :hover {
      transform: scale(1.1);
  }
  display: none;
  @media screen and (min-width: 900px){
    display: grid;
    place-items:center;
  }
`

const ButtonLink = styled(Link)`
  color: ${props => props.theme.colors.buttonText};
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: ${props => props.theme.spacing};
  ${Button}:hover & {
  }
`

const ButtonPrimary = ({ buttonText, link }) => {
  return (
    <Button onClick={e => e.preventDefault}>
      <ButtonLink to={link ? link : "#"}>{buttonText} </ButtonLink>
    </Button>
  )
}

export default ButtonPrimary
