import React from "react"
import styled from "styled-components"
import HeroImg from "../assets/YN_hero.svg"
import blob from "../assets/Big_blob.svg"
import ButtonPrimary from "./ButtonPrimary"

const HeroSection = styled.section`
  z-index: 1;
  position: relative;
  height: 90vh;
  display: grid;
  place-items: center;
  grid-template-columns: 8% repeat(6, 1fr) 8%;
  @media screen and (min-width: 1500px) {
    margin-bottom: 6%;
  }
  /* for iPad pro: */
  @media screen and (min-device-width: 768px) and (max-device-width: 1024px) and (min-device-height: 1300px) {
    margin-top: -15rem;
    margin-bottom: -15rem;
  }
`
const HeroInfo = styled.article`
  background-color: transparent;
  grid-column: 2/8;
  z-index: 5;
  text-align: center;
  @media screen and (min-width: 900px) {
    grid-column: 2/4;
    text-align: left;
  }
`
const HeroImage = styled.img`
  z-index: 4;
  width: 100%;
  grid-column: 2/8;
  @media screen and (min-width: 900px) {
    grid-column: 4/8;
  }
`
const H1 = styled.h1`
  margin-bottom: 1rem;
  @media screen and (min-width: 900px) {
    margin-bottom: 1rem;
  }
  @media screen and (min-width: 1220px) {
    margin-bottom: 3rem;
  }
`
const H4 = styled.h4`
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
  @media screen and (min-width: 900px) {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }
  @media screen and (min-width: 1220px) {
    margin-bottom: 4rem;
  }
`
const Blob = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  height: 35%;
  opacity: 0.5;
  @media screen and (min-width: 900px) {
    height: 50%;
    width: 15%;
    opacity: 1;
  }
`

const Hero = () => {
  return (
    <>
      <Blob src={blob} alt="blob" />
      <HeroSection>
        <HeroInfo>
          <H1>
            Welcome to <br></br> Young Neuros!
          </H1>
          <H4>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum
            consequuntur asperiores accusantium.
          </H4>
          <ButtonPrimary buttonText="Learn more" link="#features" />
        </HeroInfo>
        <HeroImage src={HeroImg} alt="hero-image"></HeroImage>
      </HeroSection>
    </>
  )
}

export default Hero
