import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Description from "../components/Description"
import Features from "../components/Features"
import Pathologies from "../components/Pathologies"
import BlogCards from "../components/BlogCards"
import NeuroSkills from "../components/NeuroSkills"

const IndexPage = ({ data }) => {
  const {
    allStrapiPathologies: { nodes: pathologies },
    allStrapiNeuroSkills: { nodes: neuroskills },
  } = data

  return (
    <Layout>
      <Hero />
      <Description />
      <Features />
      <Pathologies
        pathologies={pathologies}
        title="Learn about neurological pathologies"
        showLink={true}
      />
      <NeuroSkills
        neuroskills={neuroskills}
        title="Develop your skills"
        showLink={true}
      />
      <BlogCards />
    </Layout>
  )
}
// ...GatsbyImageSharpFluid

export const query = graphql`
  {
    allStrapiPathologies(limit: 3) {
      nodes {
        id
        pathology_name
        path_slug
        path_desc
      }
    }
    allStrapiNeuroSkills(limit: 3) {
      nodes {
        id
        ns_slug
        neuroskill_name
        ns_desc
      }
    }
  }
`

export default IndexPage
