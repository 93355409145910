import React from "react"
import styled from "styled-components"
import Title from "./Title"
import features from "../constants/features"
import blob1 from "../assets/Blob1.svg"
import blob2 from "../assets/Blob2.svg"
import blob3 from "../assets/Blob3.svg"
import blob4 from "../assets/Blob4.svg"

const Section = styled.section`
  display: grid;
  grid-template-columns: 8% 1fr 1fr 8%;
`
const Article = styled.article`
  grid-column: 2/4;
  padding: 1rem 0;
  display: grid;
  grid-template-columns: repeat (6, 1fr);
  margin-bottom: 2rem;
`
const FeatureImage = styled.img`
  z-index: 2;
  width: 100%;
  grid-column: 1/7;
  grid-row: 1/1;
  justify-self: center;
  overflow: visible;
  @media screen and (min-width: 900px) {
    grid-column: ${props => (props.imgLeft ? "4/7" : "1/4")};
    grid-row: 1/1;
  }
`
const FeatureInfo = styled.div`
  grid-column: 1/7;
  grid-row: 2/3;
  text-align: center;
  align-self: center;
  padding: 3rem 1rem;
  background-color: ${props => props.theme.colors.surface1};
  box-shadow: ${props => props.theme.elevations.elevation3};
  border-radius: ${props => props.theme.radius};
  margin-top: -2rem;
  @media screen and (min-width: 900px) {
    grid-column: ${props => (props.imgLeft ? "2/7" : "1/6")};
    grid-row: 1/1;
    text-align: left;
    margin-top: 0;
    padding: 6rem 3rem;
    margin-left: ${props => (props.imgLeft ? "2rem" : "0")};
    display: grid;
    grid-template-columns: ${props => (props.imgLeft ? "40% 60%" : "60% 40%")};
  }
`
const H3 = styled.h3`
  grid-column: ${props => (props.imgLeft ? "2/3" : "1/2")};
  margin-bottom: 2rem;
`
const P = styled.p`
  grid-column: ${props => (props.imgLeft ? "2/3" : "1/2")};
`

const Blob1 = styled.img`
  position: absolute;
  right: -5%;
  top: -15%;
  opacity: 0.4;
  @media screen and (min-width: 900px) {
    opacity: 0.8;
  }
`
const Blob2 = styled.img`
  z-index: -1;
  position: absolute;
  left: -5%;
  top: 35%;
`
const Blob3 = styled.img`
  z-index: -1;
  position: absolute;
  left: 7%;
  top: 43%;
`
const Blob4 = styled.img`
  z-index: -1;
  position: absolute;
  right: 0;
  bottom: -10%;
`

const Features = () => {
  return (
    <div id="features">
      <Blob1 src={blob1} alt="blob" />
      <Blob2 src={blob2} alt="blob" />
      <Blob3 src={blob3} alt="blob" />
      <Blob4 src={blob4} alt="blob" />
      <Title title="Features" />
      <Section>
        {features.map((feature, index) => {
          const { id, img_src, title, text } = feature
          return (
            <Article key={id}>
              <FeatureImage
                src={img_src}
                alt={title}
                imgLeft={id % 2 === 0 ? true : false}
              />
              <FeatureInfo imgLeft={index % 2 === 0 ? true : false}>
                <H3 imgLeft={index % 2 === 0 ? true : false}>{title}</H3>
                <P imgLeft={index % 2 === 0 ? true : false}>{text}</P>
              </FeatureInfo>
            </Article>
          )
        })}
      </Section>
    </div>
  )
}

export default Features
