import React from "react"
import Title from "./Title"
import NeuroSkill from "./NeuroSkill"
import { Link } from "gatsby"

const NeuroSkills = () => {
  return <h2>NeuroSkills list</h2>
}

export default NeuroSkills
